@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-ExtraBlack.ttf") format("WOFF");
  font-weight: 900;
}

@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-Black.ttf") format("WOFF");
  font-weight: 800;
}

@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-ExtraBold.ttf") format("WOFF");
  font-weight: 700;
}

@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-Bold.ttf") format("WOFF");
  font-weight: 600;
}

@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-SemiBold.ttf") format("WOFF");
  font-weight: 500;
}

@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-Light.ttf") format("WOFF");
  font-weight: 300;
}

@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-Thin.ttf") format("WOFF");
  font-weight: 200;
}

@font-face {
  font-family: "Yekan";
  src: url("./assets/fonts/YekanBakh-Regular.ttf") format("WOFF");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf") format("WOFF");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: "Yekan" sans-serif;
  font-weight: normal;
}

@layer base {
  * {
    @apply font-yekan;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
@layer utilities {
  .card-bg {
    background: linear-gradient(246.01deg,#1b70d5 2.11%,#052055 97.26%);
  }
}
